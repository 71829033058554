import PyTorch from '../images/PyTorch.png';
import python from '../images/python.png';
import scikit from '../images/scikit.png';
import keras from '../images/keras.png';
import Matplotlib from '../images/Matplotlib.png';
import nn from '../images/nn.png';
import js from '../images/js.png';
import andriodstudio from '../images/andriodstudio.png';
import mysql from '../images/mysql.png';
import mongodb from '../images/mongodb.png';
import firebase from '../images/firebase.png';
import Jupyter from '../images/Jupyter.png';
import Tensorflow from '../images/Tensorflow.png';
import jquery from '../images/jquery.png';
import strapi from '../images/strapi.png';
import css from '../images/css.png';
import bootstrap from '../images/bootstrap.png';
import html from '../images/html.png';
import react from '../images/react.png';
import reactnative from '../images/reactnative.png';
import dotnet from '../images/dotnet.png';
import angular from '../images/angular.png';
import express from '../images/express.png';
import node from '../images/node.png';
import studio from '../images/studio.png';
import vscode from '../images/vscode.png';
import git from '../images/git.png';
import github from '../images/github.png';
import rapidapi from '../images/rapidapi.png';
import figma from '../images/figma.png';
import netlify from '../images/netlify.png';
import ybi from '../images/ybi.jpg';
import oasis from '../images/oasis.jpg';
import internship1 from '../images/internship1.png';
import internship2 from '../images/internship2.png';
import reactcerti from '../images/reactcerti.png';
import pycerti from '../images/pycerti.jpg';
import webcerti from '../images/webcerti.png';
import dotnetcerti from '../images/dotnetcerti.jpg';
import edyoda from '../images/edyoda.jpg';
import udemy from '../images/udemy.png';
import furquan from '../images/furquan.jpeg';
import travel from '../images/travel.png';
import ecommerce from '../images/ecommerce.png';
import todo from '../images/todo.png';
import quiz from '../images/quiz.png';
import vehicle from '../images/vehicle.jpg';
import sop from '../images/sop.png';
import adminpanel from '../images/adminpanel.png';
import careme from '../images/careme.png';
import ou from '../images/ou.jpg';
import cgr from '../images/cgr.png';
import nims from '../images/nims.png';
import chaitanya from '../images/chaitanya.png';
import mitius from '../images/mitius.jpeg';
import langchain from '../images/lanchain.png';


export const Bio = {
  name: "Mohammed Furquan Saleem",
  roles: [
    "GenAI Engineer",
    "Pytorch Developer",
    "Data Scientist",
    "Python Programmer",
    "MERN Stack Developer",
    "Mobile App Developer",
  ],
  description:
    "I hold the position of Associate GenAI Developer at Millennium Info Tech Ltd. I graduated with a B.E. in Computer Science, specializing in Artificial Intelligence and Machine Learning (AIML). Throughout my academic journey, I immersed myself in the world of AI and ML, undertaking various projects to enhance my skills and knowledge. Additionally, I possess proficiency in web and mobile app development.",
  github: "https://github.com/furquanSal",
  resume:
    "https://drive.google.com/file/d/1TuNBfXfP5iYF1B9MuNNMDbs-eTWYZks5/view",
  linkedin: "https://www.linkedin.com/in/furquansal/",
  twitter: "https://twitter.com/furquan_sal",
  insta: "https://www.instagram.com/furquan.sal/",
  facebook: "https://www.facebook.com/mohammed.furquan.142/",
};

export const skills = [
  {
    title: "AI & ML",
    skills: [
      {
        name: "PyTorch",
        image: PyTorch
      },
      {
        name: "Python",
        image: python
      },
      {
        name: "Scikit-Learn",
        image: scikit,
      },
      {
        name: "LangChain",
        image: langchain,
      },
      {
        name: "Keras",
        image: keras
      },
      {
        name: "Jupyter",
        image: Jupyter
      },
      {
        name: "TensorFlow",
        image: Tensorflow
      },
      {
        name: "Matplotlib",
        image: Matplotlib
      },
      {
        name: "Neural Networks",
        image: nn
      },
    ],
  },
  {
    title: "Web Development",
    skills: [
      {
        name: "ReactJS",
        image: react
      },
      {
        name: "HTML5",
        image: html
      },
      {
        name: "CSS",
        image: css
      },
      {
        name: "JavaScript",
        image: js
      },
      {
        name: "Bootstrap",
        image: bootstrap
      },
      {
        name: "Firebase",
        image: firebase
      },
      {
        name: "MongoDB",
        image: mongodb
      },
      {
        name: "MySQL",
        image: mysql
      },
      {
        name: "jQuery",
        image: jquery,
      },
      {
        name: "DotNet",
        image: dotnet,
      },
      {
        name: "NodeJS",
        image: node,
      },
    ],
  },
  {
    title: "App Development",
    skills: [
      {
        name: "React Native",
        image: reactnative
      },
      {
        name: "Strapi",
        image: strapi,
      },
      {
        name: "ExpoGO",
        image:
          "https://www.cdnlogo.com/logos/e/72/expo-go-app.svg",
      },
      {
        name: "DotNet",
        image: dotnet,
      },
      {
        name: "JavaScript",
        image: js
      },
      {
        name: "Firebase",
        image: firebase
      },
      {
        name: "Android Studio",
        image: andriodstudio,
      },
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Visual Studio",
        image: studio,
      },
      {
        name: "VS Code",
        image: vscode,
      },
      {
        name: "Git",
        image: git,
      },
      {
        name: "GitHub",
        image: github,
      },
      {
        name: "Angular",
        image: angular,
      },
      {
        name: "ExpressJS",
        image: express,
      },
      {
        name: "RapidAPI",
        image: rapidapi,
      },
      {
        name: "Netlify",
        image: netlify,
      },
      {
        name: "Figma",
        image: figma
      },
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: mitius,
    role: "Associate GenAI Developer",
    company: "Millennium Info Tech Ltd.",
    date: "Jan 2024 - Present",
    desc: "Working with GenAI-driven projects.",
    skills: [
      "Generative AI (GenAI),",
      "LangChain,",
      "Python"
    ],
    // doc: "None",
  },
  {
    id: 1,
    img: oasis,
    role: "Web Developer Intern",
    company: "Oasis Infobyte",
    date: "June 2023 - July 2023",
    desc: "Created static and dynamic websites.",
    skills: [
      "HTML5,",
      "CSS,",
      "JavaScript,",
      "ReactJS,",
      "MongoDB,",
      "jQuery",
    ],
    doc: internship1,
  },
  {
    id: 2,
    img: ybi,
    role: "Data Science and Machine Learning Intern",
    company: "YBI Foundation",
    date: "May 2023 - June 2023",
    desc: "Worked with multiple dataset and trained machine learning model.",
    skills: [
      "Machine Learning,",
      "Deep Learning,",
      "Python",
    ],
    doc: internship2,
  },
];

export const certifications = [
  {
    id: 0,
    img: udemy,
    name: ".NET8 Core MVC",
    company: "Udemy",
    date: "December 06, 2023",
    certID: "UC-d2c8293a-4a6e-4975-bcf2-e62c28fbd63c",
    skills: [
      "C#,",
      "Angular,",
      "ReactJS,",
      "MVC,",
      "Visual Studio",
    ],
    doc: dotnetcerti,
  },
  {
    id: 1,
    img: udemy,
    name: "Python",
    company: "Udemy",
    date: "October 27, 2023",
    certID: "UC-f3f9d5c3-fb49-49ad-b705-d714f2ee8ea3",
    skills: [
      "Python,",
      "Web Development,",
      "Flask,",
      "Django",
    ],
    doc: pycerti,
  },
  {
    id: 2,
    img: edyoda,
    name: "ReactJS",
    company: "Edyoda University",
    date: "August 06, 2023",
    certID: "FSR291222B-12103-41",
    skills: [
      "ReactJS,",
      "JavaScript,",
      "JSON,",
      "JSX",
    ],
    doc: reactcerti,
  },
  {
    id: 3,
    img: edyoda,
    name: "Web Development",
    company: "Edyoda University",
    date: "MAy 14, 2023",
    certID: "FSR291222B-12103-31",
    skills: [
      "HTML5,",
      "CSS,",
      "JavaScript",
    ],
    doc: webcerti,
  },
];

export const education = [
  {
    id: 0,
    img: ou , 
    school: "Osmania University, Hyderabad",
    date: "Nov 2020 - June 2024",
    grade: "8.0 CGPA (Distinction)",
    desc: "I graduated from the Computer Science Engineering program with a specialization in Artificial Intelligence and Machine Learning at Osmania University in June 2024, with a CGPA of 8.0 and passed with distinction. Over the course of my four-year engineering program, I acquired a comprehensive understanding of various concepts within the field of artificial intelligence, machine learning, and deep learning. My expertise extends to essential tasks such as data pre-processing, data cleaning, and the development of machine learning models. Additionally, I possess proficiency in managing databases, particularly MySQL, and have a solid understanding of SQL. Furthermore, my education encompassed a broad spectrum of topics, including computer networks and compiler design. These courses have equipped me with a well-rounded knowledge base, enhancing my capabilities in the realm of computer science and engineering.",
    degree: "Bachelor of Engineering - Computer Science and Engineering with specialisation in Artificial Intelligence & Machine Learning",
  },
  {
    id: 1,
    img: chaitanya,     
    school: "Sri Chaitanya Junior Kalasala, Hyderabad",
    date: "Apr 2018 - Apr 2020",
    grade: "88.1%",
    desc: "Completed my intermediate education in the 11th and 12th grades under the Telangana State Board of Intermediate Education, specializing in the stream of Mathematics, Physics, and Chemistry. I secured an excellent overall percentage of 88.1% during these two years.",
    degree: "Telangana State Board of Intermediate Education (TSBIE)",
  },
  {
    id: 2,
    img: cgr,
    school: "CGR International School, Hyderabad",
    date: "Apr 2016 - Apr 2018",
    grade: "A",
    desc: "Completed my 10th-grade education from the Central Board of Secondary Education (CBSE) in 2018, securing an outstanding grade A.",
    degree: "Central Board of Secondary Education (CBSE)",
  },
  {
    id: 3,
    img: nims,
    school: "New Indian Model School, Dubai",
    date: "Apr 2006 - Apr 2016",
    grade: "A",
    desc: "Completed studies from LKG to 8th standard at NIMS, Dubai, under the CBSE board, achieving high grades and participating in many co-curricular activities.",
    degree: "Central Board of Secondary Education (CBSE)",
  },
];

export const projects = [
  {
    id: 9,
    title: "Travel Advisor App",
    date: "Jul 25, 2023",
    description:
      " Designed and developed a Travel Advisor application using React.js seamlessly integrating the Travel Advisor API and Google-APIs. Enabled dynamic search, filtering, and location-based recommendations for restaurants, hotels, and attractions. Demonstrated expertise in React.js and effective API utilization.",
    image: travel,
    tags: [
      "React Js",
      "MongoDb",
      "NodeJS",
      "ExpressJS",
      "RestAPIs",
    ],
    category: "web app",
    github: "https://github.com/furquanSal/Travel-Advisor-App.git",
    webapp: "https://travel-advisor-app-furquan.netlify.app/",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 10,
    title: "CareME360 Mobile App",
    date: "Dec 17, 2023",
    description:
      "Introducing careME360, a cross-platform mobile app revolutionizing healthcare accessibility. Seamlessly find nearby hospitals and doctors based on live location, with an emergency button connecting users to the nearest hospital's ambulance instantly. Beyond location services, the app offers a suite of health features, including BMI calculation, AI-driven support for mental health, and personalized medicine reminders. Experience comprehensive healthcare at your fingertips with careME360.",
    image: careme,
    tags: ["React Native", "JavaScript", "Axios", "Strapi", "CLerk", "APIs"],
    category: "android app",
    github: "https://github.com/furquanSal/careME360.git",
    webapp: "https://as1.ftcdn.net/v2/jpg/01/26/39/54/1000_F_126395469_FnlY3ZgaAZaosjmSbkgA3I5sS3UXN78T.jpg",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 2,
    title: "Vehicle Detection",
    date: "Oct 16, 2023",
    description:"Developed an advanced vehicle detection and counting model using YOLOv5, enhancing object recognition and tracking capabilities. The project showcases proficiency in computer vision and deep learning, providing accurate and efficient solutions for traffic monitoring and analysis.",
    image: vehicle,
    tags: ["Python", "Keras", "TensorFlow", "yoloV5", "cv2", "Numpy"],
    category: "machine learning",
    github: "https://github.com/furquanSal/vehicle-detector.git",
    webapp: "https://as1.ftcdn.net/v2/jpg/01/26/39/54/1000_F_126395469_FnlY3ZgaAZaosjmSbkgA3I5sS3UXN78T.jpg",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 0,
    title: "ToDo Webapp",
    date: "Feb 03, 2023",
    description:
      "Developed a dynamic ToDo list web application using HTML, CSS, and JavaScript, featuring an intuitive user interface. Implemented seamless Light Mode/Dark Mode functionality to enhance user experience and foster productivity.",
    image: todo,
    tags: [
      "HTML5",
      "Bootstrap",
      "CSS",
      "JSON",
      "JavaScript",
    ],
    category: "web app",
    github: "https://github.com/furquanSal/todo-webapp.git",
    webapp: "https://furquansal.github.io/todo-webapp/",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 1,
    title: "E-Commerce",
    date: "Nov 11, 2023",
    description:"Designed and developed a dynamic e-commerce website for wireless earphones using HTML, CSS, and JavaScript, seamlessly blending functionality with an engaging user interface for an enhanced online shopping experience.",
    image:ecommerce,
    tags: [
      "HTML5",
      "CSS",
      "JavaScript",
      "Bootstrap",
      "JSON",
    ],
    category: "web app",
    github: "https://github.com/furquanSal/WirelessEarphones-eCommerceWebsite.git",
    webapp: "https://famous-valkyrie-ee04b6.netlify.app/",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 4,
    title: "Basic Admin Panel",
    date: "Dec 19, 2022",
    description: "Designed and developed a robust admin panel webpage leveraging HTML, CSS, and JavaScript, with a significant emphasis on JavaScript for enhanced functionality. The project seamlessly integrates a user-friendly interface with dynamic features, showcasing the intersection of design and JavaScript-driven interactivity.",
    image:adminpanel,
    tags: ["ReactJs", "JSON", "JavaScript", "HTML5","CSS"],
    category: "web app",
    github: "https://github.com/furquanSal/basicadminpanel.git",
    webapp: "https://furquansal.github.io/basicadminpanel/",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 7,
    title: "Quiz App",
    date: "Mar 04, 2023",
    description:
      "Engineered an engaging Quiz Webpage utilizing HTML, CSS, and JavaScript, with a pivotal focus on JavaScript for dynamic functionality. Implemented a scoring system to reward correct answers, delivering an interactive and educational user experience that seamlessly combines design elements with JavaScript-driven interactivity.",
    image:quiz,
    tags: ["HTML5", "CSS", "JavaScript", "XML", "JSON"],
    category: "web app",
    github: "https://codepen.io/furquan-sal/pen/xxarKXJ",
    webapp: "https://codepen.io/furquan-sal/full/xxarKXJ",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
  {
    id: 8,
    title: "SOP Generator",
    date: "Sept 04, 2023",
    description:
      "Pioneered an innovative Statement of Purpose (SOP) Generator that enables users to input their information through a form, generates a personalized SOP using advanced natural language processing, and promptly delivers the customized document to their provided email address, exemplifying a harmonious blend of cutting-edge technology and user-centric design.",
    image: sop,
    tags: ["Python", "openAI", "flask", "Nodemailer", "ReactJS", "APIs"],
    category: "machine learning",
    github: "https://github.com/furquanSal/SOP-Generator.git",
    webapp: "https://drive.google.com/file/d/18ZHP_nFn2gscF_xRl2zdsLKy9KoZiABG/view",
    member: [
      {
        name: "Mohammed Furquan Saleem",
        img: furquan,
        linkedin: "https://www.linkedin.com/in/furquansal/",
        github: "https://github.com/furquanSal/",
      },
    ]
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
